<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Quotation: #{{ quotation.jobNumber }}
          <span class="float-right">Quotation lost</span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <router-link :to="`/quotations/view/${quotation.id}`">
          <v-btn small>Cancel</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-8">
        <v-textarea label="Why was the quotation lost?" v-model="reason"></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn small @click="saveQuotation">Save</v-btn>
      </v-col>
    </v-row>
    <v-dialog
        v-model="showMessage"
        max-width="290"
      >
        <v-card>
          <v-card-text class="pt-4">
            {{ message }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="orange darken-2"
              text
              @click="$router.push('/quotations/index')"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'QuotationLost',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      quotation: {},
      message: '',
      showMessage: false,
      reason: '',
    };
  },
  methods: {
    getQuotation() {
      axios.get(`/quotations/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.quotation = response.data;
      });
    },
    saveQuotation() {
      const postData = {};
      postData.quotation = this.$route.params.id;
      postData.reason = this.reason;
      postData.user = this.userid;
      axios.post(`/quotations/lost/${this.$route.params.id}.json?token=${this.token}`, postData)
      .then((response) => {
        this.message= response.data.message;
        this.showMessage = true;
      });
    }
  },
  mounted() {
    this.getQuotation();
  },
};
</script>

